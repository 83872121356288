.dc-dataset-page {
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 25px auto;
}

.dc-dataset-page .body h2{
}

.dc-dataset-page .description{
    color: #216272 !important;
}

.topic {
    color: #003347 !important;

}

.topic {
    fill: #003347 !important;
}

.map-link .dc-block-wrapper {
    color: #42919e;
    font-weight: 600;
}

.map-link .dc-block-wrapper img{
    margin-right: 5px;
}
.map-link .website .dc-block-wrapper{
    color: #003347 !important;
}
.map-link .dc-block-wrapper:hover{
    box-shadow: 0px 1px 1px rgba(66, 145, 158,0.15);
    transition: 0.3s;
}

.notice {
    font-size:13px;
    color: #bd5f08;
    text-align:justify;
}

.citation {
    font-size:13px;
    color: #003347;
    text-align:left !important;
}

.citation h5 {
    text-align: left;
}

.citation span {
    color: #0c77a1;
}

.notice em {
    font-weight: 800;
    font-style: normal;
}

.map-link a {
    text-decoration: none;
}

.dc-block-wrapper {
    padding:20px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(66, 145, 158,0.15);
    border: none !important;
    text-align:center;
    margin-bottom: 8px !important;
    border-radius: 12px !important;
}

.dc-file-download {
    padding:20px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(66, 145, 158,0.15);
    transition: 0.5s;
    border-radius: 16px;
    text-decoration: none;
    font-weight: 600;
}

.dc-file-download .dkan-icon {
    margin-right: 15px;
    position: relative !important;
}

.dc-file-download a {
    line-height: 50px;
    display: inline-block;
    position: absolute;
    width: 100%;
    width: auto;
    height: 100%;
}

.dc-file-download a:hover {
    text-decoration: none;
}

.organization {
    margin-top:10px;
    font-size: 17px;
    font-weight: 600;
    color: #216272;
    float:left;
}

.dc-item-theme {
    float:left;
    font-size: 17px;
    font-weight: 600;
    color: #216272;
}

.dc-dataset-page .header {
    margin-bottom:20px;
}

.dc-dataset-page .header h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    padding-left: 0px !important;
    text-align: left;
    color: #003347;
}

.dc-block-wrapper .number{
    font-size:32px;
}

.dc-block-wrapper .text{
    display:block;
    font-size:17px;
}

.dc-block-wrapper.samples .icon{
    content:  url('../../assets/images/clipboard.svg');
    
    width: 30px;
    height:30px;
    margin-right:5px;
    vertical-align: -3px;
  }
  
  .dc-block-wrapper.sites .icon{
    
    width: 30px;
    height:30px;
    margin-right:5px;
    vertical-align: -3px;
    content:  url('../../assets/images/map-pin.svg');
}

.dc-block-wrapper.dates .icon{
    
    width: 30px;
    height:30px;
    margin-right:5px;
    vertical-align: -3px;
    content:  url('../../assets/images/calendar.svg');
}

.dc-block-wrapper.genes .number {
    font-size:26px;
}

.tag {
    border-radius: 8px !important;
    background-color: #eee !important;
    font-size: 14px !important;
    font-family: Open sans, sans-serif !important;
    font-weight:600;
    color: #003347 !important; 
}

.tags a {
    margin-right:8px;
    margin-top: 8px;
display: inline-block;
}

.tags a:hover {
    text-decoration:none !important;
}

.tags {
    margin-bottom:30px;
}

.table-bordered {
    border-radius: 8px !important;
    box-shadow: 0px 4px 4px rgba(66, 145, 158,0.15);
}

.dc-table td, .dc-table tr {
    height: 40px !important;
    vertical-align:middle; 
    border: none !important;
    padding:10px;
}
.dc-table tr:hover {
    background-color: #fbfbfb !important;
}


.dc-table thead td, .dc-table thead tr {
    height: 40px !important;
    vertical-align:middle; 
}

.dc-table {
    border-collapse: separate;
    border-spacing: revert;

}

.dc-table tr th {
    /* background: #003347; */
    /* border-top-color: #003347; */
    /* border-bottom: none; */
    /* color: #fff; */
    /* font-weight: 300; */
    background: #fff !important;
    color: #003347 !important;
    font-weight:600;
    border: none !important;
}

.dc-table thead {
    border-collapse: collapse !important;
    border-spacing: revert !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fafafa;
}

.metadata-wrapper h3 {
    display: none;
}