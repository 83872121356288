$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutBack: cubic-bezier(0.175,  0.885, 0.320, 1.275);
$expandSize: 7px;

.dc-datatable {
  position:relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0, .1);
  overflow: auto;

  * {
    box-sizing: border-box;
  }

  &.density-1 .dc-tbody .dc-td {
    padding: 21px 5px;
  }
  &.density-2 .dc-tbody .dc-td {
    padding: 14px 5px;
  }
  &.density-3 .dc-tbody .dc-td {
    padding: 5px 5px;
  }
  &.-striped .dc-tr:nth-child(odd) .dc-td {
    background-color: rgba(0,0,0,.05);
  }
  &.-highlight .dc-tbody .dc-tr:not(.-padRow):hover .dc-td {
    background-color: #FFFEEE;
  }

  .input-select-style {
    border: 1px solid rgba(0,0,0,0.1);
    background: white;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline-width: 0;
  }

  .dc-table {
    margin: 0;
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
  }

  .dc-thead {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.-headerGroups {
      background: rgba(0,0,0,.03);
      border-bottom: 1px solid rgba(0,0,0,.05);
    }

    &.-filters {
      border-bottom: 1px solid rgba(0,0,0,0.05);
      input {
        width: 100%;
      }
    }

    .th {
      border-right: 1px solid rgba(0,0,0,0.1)
    }

    &.-header {
      .th {
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    tr {
      text-align: center;
    }
  }

  .-pagination {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0px 15px 0px rgba(0,0,0, .1);
    border-top: 2px solid rgba(0,0,0, .1);
  }

  .-btn {
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 1em;
    color: rgba(0,0,0, .6);
    background: rgba(0,0,0, .1);
    transition: all .1s ease;
    cursor: pointer;
    outline-width: 0;
    &[disabled] {
      opacity: .5;
      cursor: default;
    }
    &:not([disabled]):hover {
      background: rgba(0,0,0, .3);
      color: white;
    }
  }

  .-previous,
  .-next {
    flex: 1;
    text-align: center;
  }

  .-center {
    flex: 1.5;
    text-align: center;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  .th,
  .td {
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: 1px solid rgba(0,0,0,0.1);
    transition: box-shadow .3s $easeOutBack;
    box-shadow:inset 0 0 0 0 transparent;
    &.-sort-asc {
      box-shadow:inset 0 3px 0 0 rgba(0,0,0, .6);
    }

    &.-sort-desc{
      box-shadow:  inset 0 -3px 0 0 rgba(0,0,0, .6);
    }

    &.-cursor-pointer {
      cursor: pointer;
    }
    &:last-child {
      border-right: 0;
    }

    .resizer {
      display: inline-block;
      position: absolute;
      width: 36px;
      top: 0;
      bottom: 0;
      right: -18px;
      cursor: col-resize;
      z-index: 10;
    }
  }
}
