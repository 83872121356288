@import "../variables";

.dc-search-list-item {
  padding: 24px;
  color: #216272;
  box-shadow: 0px 4px 4px rgba(66, 145, 158, 0.15);

  /* White */
  background: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  position:relative;
  list-style: none;
  margin-bottom: 2rem;
  word-break: break-word;
  a {
    color: $headingColor;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  h2 {
    margin: 8px 0;
    font-weight: 600;
  }
  .item-description {
    padding-top: 1rem;
  }
  .dc-item-publisher {
    color: $primary;
    font-style: italic;
    margin: .5em 0;
    svg {
      display:none;
      margin-right: 1rem;
      path {
        fill: $primary;
      }
    }
  }

  .dc-topic-wrapper {
    position:absolute;
    color: #42919E;
    top:10px;
    right:0px;
    font-size:12px;
    font-weight: 600;
    svg {
      display:none;
    }
  }
  .format-types {
    display: flex;
    align-items: flex-start;
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0.9em;
  }

  .format-types div[data-format="data"] {
    display: none;
  }
  .label {
    border-radius: 3px;
    color: white;
    font-size: 1.4rem;
    line-height: 1.6rem;
    white-space: nowrap;
    display: inline-block;
    padding: 5px 8px;
    margin: .75em 16px .75em 0;
    &:first-of-type {
      margin-left: 0;
    }
  }
  .label[data-format="csv"]     { background: $csvIcon; }
  .label[data-format="geojson"]     { background: $geojsonIcon; }
  .label[data-format="json"]    { background: $jsonIcon; }
  .label[data-format="pdf"]     { background: $pdfIcon; }
  .label[data-format="rdf"],
  .label[data-format="rdf+xml"] { background: $rdfIcon; }
  .label[data-format="xml"]     { background: $xmlIcon; }
  .label[data-format="zip"]     { background: $zipIcon; }
  .label[data-format="data"]    { background: $dataIcon; }
}
