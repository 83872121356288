@import "../variables";

.dc-topic-wrapper {
  padding: 5px 20px 5px 0;
  svg {
    margin-right: 6px;
    fill: $linkColor;
  }
}
a.dc-topic-wrapper {
  &:focus,
  &:hover {
    color: $linkHoverColor;
    svg {
      fill: $linkHoverColor;
    }
  }
}
.dc-item-theme {
  font-style: normal;
  letter-spacing: .25px;
  margin: .5em 0;
  padding-bottom: .75em;
  li {
    display: inline;
  }
  a,
  div {
    color: $primary;
    display: inline-block;
    position: relative;
    padding: 0 20px 0 30px;
  }
  img, svg {
    position: absolute;
    top:0;
    left:0;
    fill: $primary;
  }
}
