.stats {
  padding-top:30px;
  padding-bottom:30px;
}

.statsBlock {
  height:120px;
  background: linear-gradient(81.57deg, #003347 0%, #0D506A 100%);
  border-radius: 8px;
  color: #fff;
  text-align:center;
  margin-bottom:120px;
}

.statsBlock svg {
  margin-top: 25px;
}

.stats h3 {
  font-weight: 800;
  text-align:center;
  margin-top: 25px;
 margin-bottom:-20px;
}

.statsBlock h4 {
  margin-top: 10px;
  font-weight: 500;
  color: #fff !important;
}

.statsBlock h4 span {
  font-weight: 800;
  font-size:16px;
}

@media (max-width: 600px) {
  .statsBlock {
    margin-bottom: 0px;
  }

  .contribute {
    text-align: center;
  }
}