.data-section .about-tabs {
    margin-top: 60px;
    max-width: 460px !important;
    min-width: 0% !important;
}

.data-tabs .MuiTabs-flexContainer{
    background: #F4FBFA;
    // background: yellow;
    border-radius: 20px;
    height: 60px;
    padding: 4px;
}

.data-tabs .MuiTabs-flexContainer .MuiTab-root {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    height: 32px;

    /* identical to box height, or 114% */
    text-align: center;
    text-transform: uppercase;
    /* Teal Heavy */
    color: #216272;
}

.data-tabs .Mui-selected {
    /* Auto Layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
font-weight: 600 !important;

/* White */
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(66, 145, 158, 0.15);
border-radius: 46px;

}

.data-tabs .MuiTabs-indicator {
    display:none;
}

.contributeLink:hover {
    text-decoration:none !important;
}

.contributeLink .dc-block-wrapper {
    width: 320px !important;
    /* margin: auto; */
    margin-top: 20px;
}

.data-section {
    z-index: 1;
    position: relative;
}

.data-section {
    margin-bottom: 60px;
    font-weight: 500;
    line-height: 30px;
}

.data-section .mission h1{
    margin-top:120px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    padding-left: 0px !important;
    text-align:left;
    color: #003347
}

.data-section .mission p{    
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    color: #216272
}

.MuiDataGrid-root {
    z-index: 10000;
    font-size: 1em !important;
    font-family: 'Open Sans' !important;
    border-color: transparent !important;
    /* border: 1px solid; */
    border-radius: 30px !important;
    color: #216272 !important;
    box-shadow: 0px 4px 4px rgba(66, 145, 158, 0.15) !important;
    background: #FFFFFF !important;
}

.MuiTablePagination-root * {
    font-size: 1.2em !important;
    font-family: 'Open Sans' !important;
}

.MuiDataGrid-menuIcon *, .MuiDataGrid-sortIcon *, .MuiGridMenu-root *{
    font-size: 1em !important;
}

.dc-search-facet-container h2 {
    font-size:1.2em;
}

.dc-search-facet-container span {
    font-weight:600;
}

.dc-search-sidebar {
    z-index:10000;

}

.data-background {
    z-index: -1000;
    border-radius: 50%;
    height: 800px;
    width: 800px;
    /* width: 100%; */
    position: absolute;
    right: -300px;
    overflow-x: hidden;
    background-color: #eee;
    background-image: url('../../assets/images/database.svg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: left center;
    background-position-x: 200px;
}