.visit {
  margin-top: 60px;
}

.visit h3 {
  font-weight:800;
  text-align:center;
}

.visit .poops img{
  max-width:100px;
}

.visit .gwpp {
  min-height:165px;
  vertical-align:center;
}

.visit .poops {
  background-color: #f5fbfb;
  font-weight: 700;
}

.visit .gwpp img {
  max-width: 250px;
  margin-top:20px;
}

.visit a:hover {
  
  text-decoration:none;
  color: #42919e;
}

.visit a:hover div {
  box-shadow: 0px 1px 1px rgba(66, 145, 158, 0.15);
    transition: 0.3s;
}