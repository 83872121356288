.about-section {
    margin-bottom:150px;
    font-weight:500;
    line-height:30px;
}

.heading {
    text-align:center;
}

.heading img {
    width: 100px;
    height:100px;
    margin-bottom:20px;
}

.acronym {
    margin-bottom:30px;
}

.wastewater {
    width: 1000px;
}

.about-heading {
    position: static;
}

.about-tabs {
    display:block;
    min-width:100%;
    position:relative;
    z-index:2001;
    margin:auto;
}

// @media only screen and (max-width: 858) {
//     .about-tabs {
//       background-color: lightblue;
//     }
//   }

.sticky {
    z-index: 3000;
}

.about-tabs .MuiTabs-flexContainer{
    background: #F4FBFA;
    // background: yellow;
    border-radius: 20px;
    height: 60px;
    padding: 4px;
}

.about-tabs .MuiTabs-flexContainer .MuiTab-root {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    height: 32px;

    /* identical to box height, or 114% */
    text-align: center;
    text-transform: uppercase;
    /* Teal Heavy */
    color: #216272;
}

.about-tabs .Mui-selected {
    /* Auto Layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
font-weight: 600 !important;

/* White */
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(66, 145, 158, 0.15);
border-radius: 46px;

}

.about-tabs .MuiTabs-indicator {
    display:none;
}

.contribute-body .cta {
    margin-top: 30px;
}

.contribute-body .cta a {
    text-decoration: none;
    color: #42919e;
    font-weight: 600;
}

.contribute-body .cta a:hover .dc-block-wrapper {
    box-shadow: 0px 1px 1px rgba(66, 145, 158,0.15);
}

.contribute-body .cta img {
    margin-right: 5px;
}

.contribute-body {
    overflow-x: hidden;
    overflow-y: hidden;
}

.panel-header {
    background-color: #f9fafb !important;
    border-radius:12px !important;
    border-color: trasparent !important; 
}

.contribute-body .MuiAccordionSummary-content p{
    font-family: 'Open sans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #003347;
}

.contribute-body .MuiAccordionSummary-root {
    margin-bottom: 8px !important;
    border: none !important;
    background-color: #fff !important;
    box-shadow: 0px 4px 4px rgba(66, 145, 158,0.15) !important;
}

.contribute-body .MuiAccordion-root {
    border: none !important;
    background: none !important;
}

.contribute-body .MuiAccordionDetails-root p{
    
    font-family: 'Open sans', sans-serif !important;
    font-size: 14px !important;
}

.contribute-background {
    border-radius: 50%;
    height: 800px;
    width: 800px;
    /* width: 100%; */
    position: absolute;
    right: -300px;
    overflow-x: hidden;
    background-color: #eee;
    background-image: url('../../assets/images/database.svg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: left center;
    background-position-x: 200px;
}

.about-wrapper {
    position:relative;
}

.about-inner-wrapper {
    padding-top: 80px;
    background-color: #FBF8F4;
}

.about-section {
    z-index: 2000;
    position: relative;
}

.about-section .mission h1{
    margin-top:120px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    padding-left: 0px !important;
    text-align:left;
    color: #003347
}

.about-section .mission p{    
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    color: #216272
}

.about-section .mission p.dua{
    font-size: 16px;
}


.about-blocks {
    margin-top: 0px !important;
}
.about-heading h1 {
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 16px;

/* identical to box height, or 50% */
text-align: center;
text-transform: uppercase;

/* Teal Heavy */
color: #216272;
}

.acronym {
    color: #216272;
}

.about-heading h1 span {
    font-size:32px;
    font-family: Open Sans, sans-serif;
    font-weight:800;
    text-shadow: none;
    color: #003347;
}

.about-heading h1 span b{
    font-size:20px;
    font-family: Open Sans, sans-serif;
    font-weight:600;
    color: #20997D;
}

.donors-block {
    margin-top: 250px !important;
}

.background-block {
    position: relative;
    color: #216272;
    margin-top: 50px !important;
}

.background-block p{
}

.mission-block {
    background-color: #eee;
    padding:30px;
}

.faq-block {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

    color:#216272;
    padding-bottom: 100px;
}

h2 {
    margin-top:20px !important;
    margin-bottom:20px !important;
    font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 150%;

/* identical to box height, or 45px */

/* Dark */
color: #003347 !important;


}

.goals-block ul {
    margin-top: 40px;
    margin-bottom: 40px;
}

.goals-block ul li {
    list-style-type: none;
    margin-bottom:8px;
    padding: 24px;

/* White */
background: #FFFFFF;
border-radius: 16px;
}

.rationale-block {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 40px !important;
    color:#216272;
}

.data-block {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

    color:#216272;
    padding-bottom:40px;
}

.poops-block {
    text-align:justify;
    font-size:14px;
    list-style-type: none;
    margin-bottom:8px;
    padding: 24px;

/* White */
background: #F5FBFB;
border-radius: 16px;

}

.poops-block a{ 
    display:block;
    text-align:center;
}

.poops-block a:hover {
    text-decoration: none;
}

.public-health-block {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

    color:#216272;
}

.team-block {
    padding-top: 40px;
}

.team-block img {
    border-radius: 30px;
    width: 400px;
}

.team-block .map-block {
    background-image: url('../../assets/images/network.PNG');
    background-size: 400px 200px;
    position: relative;
    width: 400px;
    height: 200px;
    margin:auto;
    border-radius: 8px;
    box-shadow: 0px 10px 10px rgba(66, 145, 158, 0.15);
}


.team-block .map-block .layer {
    background-color: rgba(33,98,114, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 200px;
    color: #fff;
    border-radius: 8px;
}

.team-block .map-block .layer:hover {
    background-color: rgba(33,98,114, 0.8);
    text-decoration: none;
}

.team-block .map-block h4 {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-top: 90px;
}

.team-block h3 {
    margin-top: 30px;
    margin-bottom:30px;
}

.team-block .person img {
    width: 80%;
    max-height:150px;
    max-width:150px;
}


.team-block .person {
    text-align:center;
    margin-bottom:30px;
    padding: 24px;
    color: #216272;
    box-shadow: 0px 4px 4px rgba(66, 145, 158, 0.15);

    /* White */
    background: #FFFFFF;
    border-radius: 16px;
}

.team-block .person em {
    font-weight: 600;
    font-style: normal;
}

.team-block .person p {
    text-align: justify;
    font-size:14px;
}

.team-block .person h5 {
    margin-top:20px;
    text-align:center;
    font-size:15px;
    font-weight:600;
    color: #216272;
}

.team-block .person h5 span {
    display:block;
    font-size:12px;
    font-weight:500;
    color: #216272;
}

.tac {
    text-align:center;

}

.tac table {
    margin-bottom:30px;
    padding: 24px;
    color: #216272;
    box-shadow: 0px 4px 4px rgba(66, 145, 158, 0.15);

    /* White */
    background: #FFFFFF;
    border-radius: 16px;
}

.tac table th{
    padding:18px;
    font-size:14px;
}
.tac table td{
    font-size:12px;
    padding:8px;
    border-bottom:1px solid #00334710;
    vertical-align:middle;
}