.partners p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom:20px;
} 

.partners svg {
  max-width:120px;
}

.partners .partner{
  text-align:center;
  padding-top: 20px !important;
}

.disclaimer p {
  margin-top: 20px;
  font-size: 14px;
  line-height: 24px;
  margin-bottom:60px;
  font-weight: 600;
}