.globaldataset {
  background: #fff;
  background-image: url('../../assets/images/globalbg-pic.jpg');
  background-position-y:50%;
  background-size:70%;
  height: 120px;
  margin-top:80px;
  border-radius:20px;
  padding:0px;
  position: relative;
}

.globaldataset h3 {
  padding-top: 25px;
  color: #fff;
}

.globaldataset h3 span {
  padding: 10px;
  color: #fff;
  font-size:14px;
  background:#ff8d24;
  border-radius:8px;
  margin-right: 10px;
  text-transform: uppercase;
}

.globaldataset .globalcontainer {
  background: #0033478f;
  opacity: 0.9;
  position:absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 4px -4px rgba(66, 145, 158, 0.15);
  border-radius:20px;
  padding: 20px;
}

.globaldatasetButton {
  padding: 9px 24px 10px;
  border: 3px solid #216272;
  border-radius: 8px;
}

.globaldataset a {
  text-decoration: none !important;
}