@import "../variables";

.dc-results-list .dc-search-input {
  position: relative;
  #inputReset {
    position: absolute;
    color: #333333;
    top: 0;
    right: 0;
    width: auto;
    background: transparent;
    border: none;
    &:active,
    &:focus {
      background: transparent;
      border: none;
      box-shadow: none;
    }
    svg.fa-times {
      font-size: 3.2rem;
      margin-right: 15px;
      vertical-align: middle;
      path {
        fill: $grayMedium;
      }
    }
    .reset-text {
      font-size: 0;
    }
  }
}

.dc-results-list {
  .dc-search-results-message p {
    margin: 16px 0;
  }
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .dc-pagination-container {
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    select[id^="search-page-sizer"] {
      background-color: white;
      border: 1px solid $borderColor;
      height: 34px;
      margin-left: 10px;
    }
    .pagination {
      display: inline-block;
      padding-left: 0;
      margin: 20px 0 20px 20px;
      border-radius: $borderRadius;
      li {
        display: inline;
        a,
        span {
          position: relative;
          float: left;
          padding: 6px 12px;
          line-height: 1.42857143;
          text-decoration: none;
          color: $linkColor;
          background-color: #fff;
          border: 1px solid $borderColor;
          margin-left: -1px;
        }
      }
      li > a:focus,
      li > a:hover,
      li > span:focus,
      li > span:hover {
        z-index: 2;
        color: $primaryDark;
        background-color: #eee;
      }
      li:first-child > a,
      li:first-child > span {
        margin-left: 0;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
      li:last-child > a,
      li:last-child > span {
        margin-right: 0;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
      li.disabled > a,
      li.disabled > a:focus,
      li.disabled > a:hover,
      li.disabled > span,
      li.disabled > span:focus,
      li.disabled > span:hover {
        color: #777;
        background-color: #fff;
        border-color: $borderColor;
        cursor: not-allowed;
      }
      li.active > a,
      li.active > a:focus,
      li.active > a:hover,
      li.active > span,
      li.active > span:focus,
      li.active > span:hover {
        z-index: 3;
        color: #fff;
        background-color: $linkColor;
        border-color: $borderColor;
        cursor: default;
      }
    }
    .dataset-results-count {
      flex-grow: 2;
      text-align: left;
    }
  }
}

.contribute-link .dc-block-wrapper{
  color: #42919e;
    font-weight: 600;
}

.contribute-link a:hover {
  text-decoration: none;

}

.contribute-link a:hover .dc-block-wrapper {
  box-shadow: 0px 1px 1px rgba(66, 145, 158,0.15);
}