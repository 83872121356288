@import "../variables";

.dc-tag-wrapper {
  margin: 20px 0;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  .dc-tag {
    margin: 5px;
    a {
      border: 1px solid $borderColor;
      border-radius: $borderRadius;
      background-color: $grayDust;
      color: $grayDark;
      padding: 4px 12px;
      position: relative;
      &:hover,
      &:focus {
        background: #fff;
        color: #000;
        text-decoration: none;
      }
    }
  }
  h3 {
    width: 100%;
  }
}
